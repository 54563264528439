import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import axios from 'axios';

const GameActivities = () => {
  const [loading, setLoading] = useState(true);
  const [gameActivities, setGameActivities] = useState([]);

  // Fetch game activities data from the API
  useEffect(() => {
    const fetchGameActivities = async () => {
      try {
        const response = await axios.get('https://www.blueblocksol.com:3000/api/game/all-user-game-activities');
        setGameActivities(response.data);
      } catch (error) {
        console.error('Error fetching game activities:', error);
        Swal.fire('Error', 'Failed to fetch game activities from the server', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchGameActivities();
  }, []);

  const columns = [
    { name: 'Activity ID', selector: (row) => row.activity_id, sortable: true },
    // { name: 'User ID', selector: (row) => row.user_id, sortable: true },
    { name: 'User Email', selector: (row) => row.user_email, sortable: true },
    // { name: 'Game ID', selector: (row) => row.game_id, sortable: true },
    { name: 'Game Name', selector: (row) => row.game_name, sortable: true },
    { name: 'Bet Amount', selector: (row) => `$${parseFloat(row.bet_amount).toFixed(2)}`, sortable: true },
    { name: 'Win Amount', selector: (row) => `$${parseFloat(row.win_amount).toFixed(2)}`, sortable: true },
    { name: 'Loss Amount', selector: (row) => `$${parseFloat(row.loss_amount).toFixed(2)}`, sortable: true },
    { name: 'Result', selector: (row) => row.result, sortable: true },
    { 
      name: 'Play Date', 
      selector: (row) => new Date(row.play_date).toLocaleString('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }), 
      sortable: true 
    },
  ];

  return (
    <Layout loading={loading}>
      {loading ? (
        <Loader />
      ) : (
        <div className="card">
          <h5 className="card-header">Game Activities</h5>
          <div className="card-datatable text-nowrap">
            <DataTable
              columns={columns}
              data={gameActivities}
              pagination
              highlightOnHover
              striped
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default GameActivities;
