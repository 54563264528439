import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

const MyReferral = () => {
  const [referrals, setReferrals] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        // Retrieve the token from local storage
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Authorization token is missing');
        }

        // Make the API call to fetch referral details
        const response = await axios.get(`https://www.blueblocksol.com:3000/users/referrals`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        // Set referrals data from API response
        setReferrals(response.data);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching referrals:', error.message);
      }
    };

    fetchReferrals();
  }, []);

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
      style: { color: '#ff8c00' },
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      style: { color: '#ff8c00' },
    },
    {
      name: 'Referral Code',
      selector: row => row.referral_code,
      sortable: true,
    },
    {
      name: 'Referral Email',
      selector: row => row.referral_email,
      sortable: true,
    },
  ];

  return (
    <div className="table--responsive--md mt-2">
      {error && <p className="text-danger text-center">{error}</p>} {/* Display error message */}
      <DataTable
        columns={columns}
        data={referrals}
        customStyles={{
          header: {
            style: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
          },
          rows: {
            style: {
              backgroundColor: '#350b2d',
              color: 'white',
              '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#ff8c00',
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
            hoverStyle: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
          },
          headCells: {
            style: {
              backgroundColor: '#ff8c00',
              color: '#350b2d',
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              color: 'white',
            },
          },
        }}
        pagination
        noHeader
      />
    </div>
  );
};

export default MyReferral;
