import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import UserLayout from '../components/Layout';

const RequestMetacoins = () => {
  // State to handle form data
  const [formData, setFormData] = useState({
    walletAddress: '',
    amount: '',
  });

  const [loading, setLoading] = useState(true); // State to handle loading

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from local storage

        if (!token) {
          Swal.fire({
            icon: 'error',
            title: 'Authorization Error',
            text: 'Authorization token is missing.',
          });
          return;
        }

        // API call to get user details
        const response = await axios.get('https://www.blueblocksol.com:3000/users/user/details', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        if (response.status === 200) {
          setFormData((prevData) => ({
            ...prevData,
            walletAddress: response.data.wallet_address, // Set wallet address from API response
          }));
          setLoading(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch user details.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error fetching user details: ' + (error.response?.data?.message || error.message),
        });
        console.error('Error:', error.message);
      }
    };

    fetchUserDetails();
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('token'); // Retrieve token from local storage

      if (!token) {
        Swal.fire({
          icon: 'error',
          title: 'Authorization Error',
          text: 'Authorization token is missing.',
        });
        return;
      }

      // API call to submit request for Metacoins
      const response = await axios.post(
        'https://www.blueblocksol.com:3000/users/crypto-request/token', // Replace with your actual API endpoint
        { amount: formData.amount }, // API expects only amount; user ID is derived from the token
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Request Successful',
          text: 'Crypto request created successfully!',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Request Failed',
          text: 'Failed to create crypto request.',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error submitting request: ' + (error.response?.data?.message || error.message),
      });
      console.error('Error:', error.message);
    }
  };

  return (
    <UserLayout>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div
              className="card p-4 mt-5"
              style={{
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                backgroundColor: 'rgba(255, 255, 255, 0.5)', // More transparent background
                backdropFilter: 'blur(10px)', // Adds a blur effect to the background
              }}
            >
              <form onSubmit={handleSubmit}>
                <h3 className="mb-4" style={{ textAlign: 'left' }}>Request Metacoins</h3>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="row gy-3">
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="walletAddress">Wallet Address</label>
                        <input
                          id="walletAddress"
                          type="text"
                          className="form-control"
                          placeholder="Wallet Address"
                          name="walletAddress"
                          value={formData.walletAddress}
                          onChange={handleInputChange}
                          readOnly // Make the field read-only
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="amount">Amount</label>
                        <input
                          id="amount"
                          type="number"
                          className="form-control"
                          placeholder="Amount"
                          name="amount"
                          value={formData.amount}
                          onChange={handleInputChange}
                          max="1000" // Set maximum amount to 1000
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                      <button type="submit" className="btn btn-primary mt-3">
                        Submit Request
                      </button>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </UserLayout>
  );
};

export default RequestMetacoins;
