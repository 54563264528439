import React from 'react';
import DataTable from 'react-data-table-component';

const DepositHistory = () => {
  const transactions = [
    { id: '481XV93NCKD0', type: 'Withdraw', date: '12 Mar, 21 at 12:30 AM', amount: '$150.50' },

  ];

  const columns = [
    {
      name: 'Transaction ID',
      selector: row => `#${row.id}`,
      sortable: true,
      style: { color: '#ff8c00' },
    },
    {
      name: 'Transaction Type',
      selector: row => row.type,
      sortable: true,
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
      style: { color: '#ff8c00' },
    },
  ];

  return (
    <div className="table--responsive--md mt-2">
      <DataTable
        columns={columns}
        data={transactions}
        customStyles={{
          header: {
            style: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
          },
          rows: {
            style: {
              backgroundColor: '#350b2d',
              color: 'white',
              '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#ff8c00',
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
            hoverStyle: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
          },
          headCells: {
            style: {
              backgroundColor: '#ff8c00',
              color: '#350b2d',
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              color: 'white',
            },
          },
        }}
        pagination
        noHeader
      />
    </div>
  );
};

export default DepositHistory;
