import React from 'react';
import { Link } from 'react-router-dom';
import LottieAnimation from '../LottieAnimation';

const Banner = () => {
  return (
    <section className="banner-section bg_img overflow-hidden bg-theme" style={{ background: 'url(assets/images/banner/Routtle_IMG.jpg) center' }}>
      <div className="container mt-40">
        <div className="banner-wrapper">
          <div className="banner-content">
            <h1 className="banner-content__title">
              Welcome <span className="text--base" style={{ color: '#ffcc00' }}>to MetaBet Casino</span>
            </h1>
            <p className="banner-content__subtitle">
              Where Crypto Meets Entertainment Discover the Ultimate Crypto Gaming Experience with MetaBet Cash.
            </p>
            <div className="button-wrapper">
              <Link to="/" className="cmn--btn btn--lg" style={{ textDecoration: 'none' }}>
              Refer a Friend              </Link>
            </div>
            <img src="assets/images/banner/card.png" alt="" className="shape1" />
          </div>
          <div className="banner-thumb">
            <LottieAnimation />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
