import React from 'react';
import UserLayout from '../components/Layout';
import Banner from '../components/Home/Banner';
import About from '../components/Home/About';
import GameSection from '../components/Home/GameSection';
import Slideshow from '../components/Home/Slideshow';
import Footer from '../components/Footer';
import Header from '../components/Header';
import GetStarted from '../components/GetStarted';
import LottieAnimation from '../components/LottieAnimation'; // Correct path to LottieAnimation
import MobileMenu from '../components/MobileMenu';
import Spacer from '../components/Spacer';

const Home = () => {
  return (
    <div className="user-layout">
      <Header />

      <main>
      <Banner />

      {/* Add the div wrapper with the new background gradient */}
      {/* <div
        style={{
          background: 'rgb(53, 11, 45)',
          padding: '20px', // Optional: Add padding if needed
        }}
      > */}
        {/* <Slideshow userOptions={{ showArrows: true, autoplay: true, duration: 1000 }} /> */}
      {/* </div> */}

      <About />
      <GameSection />
      <GetStarted />
      </main>
      <Footer />
      <MobileMenu /> {/* Include the MobileMenu at the bottom */}

    </div>
  );
};

export default Home;
