// src/components/Loader.js
import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import './loader.css'; // Import your loader styles

const Loader = () => {
  return (
    <div className="loader-wrapper">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
