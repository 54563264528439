import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MobileMenu from './MobileMenu';

const UserLayout = ({ children }) => {
  return (
    <div className="user-layout">
      <Header />
      {/* <div className="container my-40"> Adds Bootstrap container and margin */}
        <main>{children}</main>
      {/* </div> */}
      <Footer />
      <MobileMenu /> {/* Include the MobileMenu at the bottom */}

    </div>
  );
};

export default UserLayout;
