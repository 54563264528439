import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <section className="about-section padding-top padding-bottom overflow-hidden bg-theme">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-content">
              <div className="section-header">
                <h2 className="section-header__title">Why MetaBet Casino?</h2>
                <p>
                At MetaBet Casino, we’re redefining the world of online gaming with the power of cryptocurrency. Our platform offers a secure, fast, and thrilling environment where you can bet, win, and earn in our exclusive digital currency— METABET. 
                Join (link it register) a global community of players who trust MetaBet Casino for an unmatched gaming experience.                </p>
              </div>
              {/* <Link to="/about" className="cmn--btn active">Know More</Link> */}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="aobut-thumb section-thumb">
              <img src="assets/images/about/thumb.png" alt="about" className="ms-lg-5" />
            </div>
          </div>
        </div>
      </div>
      <div className="shapes">
        <img src="assets/images/about/shape.png" alt="about" className="shape shape1" />
      </div>
    </section>
  );
};

export default About;
