import React from 'react';
import { Link } from 'react-router-dom';

const GameSection = () => {
  const games = [
    {
      id: 1,
      title: "Roulette",
      image: "assets/images/game/item2.png",
      investInfo: "Invest Limit",
      investAmount: "$10.49 - $1,000",
      link: "#"
    },
    {
      id: 2,
      title: "Zero To Ninty",
      image: "assets/images/game/item1.png",
      investInfo: "Invest Limit",
      investAmount: "$10.49 - $1,000",
      link: "#"
    },
    {
      id: 3,
      title: "Number Buy",
      image: "assets/images/game/item3.png",
      investInfo: "Invest Limit",
      investAmount: "$10.49 - $1,000",
      link: "#"
    },
    // Add more games as needed
  ];

  return (
    <section className="game-section padding-top padding-bottom bg_img" style={{ background: 'url(assets/images/game/bg3.jpg)' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-xl-5">
            <div className="section-header text-center">
              <h2 className="section-header__title">Top Awesome Games</h2>
              {/* <p className='text-white'>A casino is a facility for certain types of gambling. Casinos are often built combined with hotels, resorts, etc.</p> */}
            </div>
          </div>
        </div>
        <div className="row gy-4 justify-content-center">
          {games.map(game => (
            <div key={game.id} className="col-lg-4 col-xl-3 col-md-6 col-sm-6">
              <div className="game-item">
                <div className="game-inner">
                  <div className="game-item__thumb">
                    <img src={game.image} alt={game.title} />
                  </div>
                  <div className="game-item__content">
                    <h4 className="title">{game.title}</h4>
                    <p className="invest-info">{game.investInfo}</p>
                    <p className="invest-amount">{game.investAmount}</p>
                    <Link to={game.link} className="cmn--btn active btn--md radius-0">Play Now</Link>
                  </div>
                </div>
                <div className="ball"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default GameSection;
