import React, { useEffect, useState } from 'react';
import axios from 'axios';

const DashboardCards = () => {
  const [cards, setCards] = useState([
    { id: 1, price: '$0', info: 'TOTAL BALANCE', icon: 'las la-wallet' },
    { id: 2, price: '$0', info: 'TOTAL DEPOSIT', icon: 'las la-wallet' },
    { id: 3, price: '$0', info: 'TOTAL WITHDRAW', icon: 'las la-money-check' }
  ]);

  // Fetch transaction summary data
  useEffect(() => {
    const fetchTransactionSummary = async () => {
      const token = localStorage.getItem('token'); // Retrieve token from local storage
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await axios.get('https://www.blueblocksol.com:3000/users/user/transactions-summary', {
          headers: {
            Authorization: `Bearer ${token}` // Pass token in Authorization header
          }
        });

        const { total_deposit_balance, total_withdrawal_balance,current_wallet_balance } = response.data;

        // Update card data based on the API response
        setCards([
          { id: 1, price: `$${current_wallet_balance}`, info: 'TOTAL BALANCE', icon: 'las la-wallet' },
          { id: 2, price: `$${total_deposit_balance}`, info: 'TOTAL DEPOSIT', icon: 'las la-wallet' },
          { id: 3, price: `$${total_withdrawal_balance}`, info: 'TOTAL WITHDRAW', icon: 'las la-money-check' }
        ]);

      } catch (error) {
        console.error('Error fetching transaction summary:', error);
      }
    };

    // Call the function initially
    fetchTransactionSummary();

    // Set an interval to call the function every 2 seconds
    const intervalId = setInterval(fetchTransactionSummary, 2000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="row justify-content-center g-4">
      {cards.map(card => (
        <div key={card.id} className="col-lg-6 col-xl-4 col-md-6 col-sm-10">
          <div className="dashboard__card">
            <div className="dashboard__card-content">
              <h2 className="price">{card.price}</h2>
              <p className="info">{card.info}</p>
              <a href="#0" className="view-btn">View All</a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCards;
