import React from 'react';
import Lottie from 'lottie-react';
import groovyWalkAnimation from "../casino.json";

const LottieAnimation = () => {
  return (
    <div>
      <Lottie
        autoplay
        loop
        animationData={groovyWalkAnimation}         
        style={{ height: '100%', width: '100%' }} // Customize height and width as needed
      />
    </div>
  );
};

export default LottieAnimation;
