import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { UserProvider } from './context/UserContext'; // Import UserProvider


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* Ensure UserProvider is wrapping the App component */}
    <UserProvider>
      <App />
    </UserProvider>
  </React.StrictMode>
);