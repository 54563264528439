import React, { useEffect, useRef, useState } from 'react';

const GameComponent = () => {
  const iframeRef = useRef(null);
  const [iframeSrc, setIframeSrc] = useState('https://casino.blueblockss.ai/index.html'); // Default URL without token

  useEffect(() => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('token'); // Replace 'token' with your actual key in localStorage

    if (token) {
      // If a token is found, add it as a query parameter to the iframe URL
      setIframeSrc(`https://casino.blueblockss.ai/index.html?token=${token}`);
    }
  }, []);

  return (
    <div>
      <iframe
        ref={iframeRef}
        src={iframeSrc} // Dynamically constructed URL with token as query parameter
        title="Game"
        width="1200"
        height="600"
        style={{ border: 'none' }}
      />
    </div>
  );
};

export default GameComponent;
