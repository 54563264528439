import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useUser } from '../../context/UserContext'; // Import UserContext
import UserLayout from '../components/Layout';

const UpdateProfile = () => {
  const { user } = useUser(); // Access the user context
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    birthdate: '',
    wallet_address: '',
    phone_number: '',
    email_id: '',
  });

  // Function to format the date to yyyy-MM-dd
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ensure two-digit month
    const day = String(date.getDate()).padStart(2, '0'); // Ensure two-digit day
    return `${year}-${month}-${day}`;
  };

  // Fetch user data from the API
  useEffect(() => {
    const fetchUserData = async () => {
      console.log(user);
      if (user && user.id) {
        try {
          const response = await axios.get(`https://www.blueblocksol.com:3000/api/auth/users/${user.id}`);
          const userData = response.data;

          // Log the response to debug
          console.log('Fetched User Data:', userData);

          // Set form data with the fetched user data, formatting birthdate
          setFormData({
            first_name: userData.first_name || '',
            last_name: userData.last_name || '',
            birthdate: userData.birthdate ? formatDate(userData.birthdate) : '',
            wallet_address: userData.wallet_address || '',
            phone_number: userData.phone_number || '',
            email_id: userData.email_id || '',
          });
        } catch (error) {
          console.error('Error fetching user data:', error);
          Swal.fire('Error', 'Failed to fetch user data', 'error');
        }
      }
    };

    fetchUserData();
  }, [user]);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);

    // Perform API call to update profile
    try {
      await axios.put(`https://www.blueblocksol.com:3000/api/auth/users/${user.id}`, formData);
      Swal.fire('Success', 'Profile updated successfully!', 'success');
    } catch (error) {
      console.error('Error updating profile:', error);
      Swal.fire('Error', 'Failed to update profile', 'error');
    }
  };

  return (
    <UserLayout>

    <div className="container my-5">
      <div className="row justify-content-center">
        {/* Profile Section */}
        <div className="col-xl-4 col-lg-5 col-md-6 mb-4 mt-5">
          <div
            className="card text-center p-3"
            style={{
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // More transparent background
              backdropFilter: 'blur(10px)', // Adds a blur effect to the background
            }}
          >
            <div className="card-body">
              <div className="thumb mb-3">
                <img
                  src="assets/images/account/user.png"
                  alt="Profile"
                  style={{ width: '100px', height: '100px', borderRadius: '50%' }}
                />
              </div>
              <h4 className="name">Demo User</h4>
              <p className="username text-muted">@demouser123</p>
              <input type="file" className="form-control d-none" id="update-photo" />
              <label
                className="btn btn-primary mt-3"
                htmlFor="update-photo"
                style={{ cursor: 'pointer' }}
              >
                Update Profile Picture
              </label>
            </div>
          </div>
        </div>

        {/* Profile Update Form */}
        <div className="col-xl-8 col-lg-7 col-md-6 mt-5">
          <div
            className="card p-4"
            style={{
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // More transparent background
              backdropFilter: 'blur(10px)', // Adds a blur effect to the background
            }}
          >
            <form onSubmit={handleSubmit}>
              <h3 className="mb-4" style={{ textAlign: 'left' }}>Update Profile</h3>
              <div className="row gy-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="first_name">First Name</label>
                    <input
                      id="first_name"
                      type="text"
                      className="form-control"
                      placeholder="First Name"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      id="last_name"
                      type="text"
                      className="form-control"
                      placeholder="Last Name"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="birthdate">Birthdate</label>
                    <input
                      id="birthdate"
                      type="date"
                      className="form-control"
                      name="birthdate"
                      value={formData.birthdate}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="wallet_address">Wallet Address</label>
                    <input
                      id="wallet_address"
                      type="text"
                      className="form-control"
                      placeholder="Wallet Address"
                      name="wallet_address"
                      value={formData.wallet_address}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="phone_number">Phone Number</label>
                    <input
                      id="phone_number"
                      type="tel"
                      className="form-control"
                      placeholder="Phone Number"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="email_id">Email Address</label>
                    <input
                      id="email_id"
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      name="email_id"
                      value={formData.email_id}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 d-flex justify-content-center">
                  <button type="submit" className="btn btn-primary mt-3">
                    Update Profile
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </UserLayout>

  );
};

export default UpdateProfile;
