import React from 'react';

const StatsCard = ({ iconClass, count, title, percentageChange, changeDescription }) => {
  return (
    <div className="col-lg-3 col-sm-6 mt-3">
      <div className="card card-border-shadow-primary h-100">
        <div className="card-body">
          <div className="d-flex align-items-center mb-2">
            <div className="avatar me-4">
              <span className="avatar-initial rounded bg-label-primary">
                <i className={iconClass}></i>
              </span>
            </div>
            <h4 className="mb-0">{count}</h4>
          </div>
          <p className="mb-1">{title}</p>
          <p className="mb-0">
            <span className="text-heading fw-medium me-2">{percentageChange}</span>
            <small className="text-muted">{changeDescription}</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
