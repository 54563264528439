// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme">
    <div className="container-xxl">
      <div className="footer-container d-flex align-items-center justify-content-between py-4 flex-md-row flex-column">
        <div className="text-body">
          ©  {new Date().getFullYear()}
            MetaGaiming
        </div>
      
      </div>
    </div>
  </footer>
  );
};

export default Footer;
