import React, { createContext, useContext, useState, useEffect } from 'react';
import { decodeJWT } from '../utils/jwtUtils'; // Import the utility function
import { Navigate } from 'react-router-dom';

const UserContext = createContext();

// Custom hook to use the UserContext
export const useUser = () => {
  return useContext(UserContext);
};

// Provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch token from local storage
    const token = localStorage.getItem('token');

    if (token) {
      const decodedUser = decodeJWT(token);
      setUser(decodedUser);
    } else {
      console.warn('No JWT token found in local storage.');
    }
  }, []);

  // Logout function
  const logout = () => {
    // Clear the user state
    setUser(null);

    // Remove the token from local storage
    localStorage.removeItem('token');
    Navigate('/');

    console.log('User logged out successfully.');
  };

  return (
    <UserContext.Provider value={{ user, setUser, logout }}>
      {children}
    </UserContext.Provider>
  );
};
