import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import axios from 'axios';

const Games = () => {
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editingGameId, setEditingGameId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch games data from the API
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await axios.get('https://www.blueblocksol.com:3000/api/auth/games');
        setGames(response.data);
      } catch (error) {
        console.error('Error fetching games:', error);
        Swal.fire('Error', 'Failed to fetch games from the server', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, []);

  const toggleGameStatus = (gameID) => {
    setGames((prevGames) =>
      prevGames.map((game) =>
        game.game_id === gameID ? { ...game, status: game.status === 'Active' ? 'Inactive' : 'Active' } : game
      )
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Check if an image file is uploaded and is in the correct format
    if (formData.imageFile) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
      if (!validImageTypes.includes(formData.imageFile.type)) {
        setShowModal(false); // Close the modal before showing the error
        Swal.fire('Error', 'Please upload a valid image file (jpg, jpeg, png, gif)', 'error');
        return;
      }

      // Handle file upload
      const imageData = new FormData();
      imageData.append('file', formData.imageFile);

      try {
        const uploadResponse = await axios.post('https://www.blueblocksol.com:3000/api/auth/upload', imageData);
        formData.image_url = uploadResponse.data.fileUrl; // Set image_url from upload response
      } catch (error) {
        console.error('Error uploading image:', error);
        setShowModal(false); // Close the modal before showing the error
        Swal.fire('Error', 'Failed to upload image', 'error');
        return;
      }
    }

    // Handle add or update game
    if (isEditing) {
      try {
        await axios.put(`https://www.blueblocksol.com:3000/api/auth/games/${editingGameId}`, formData);
        setGames((prevGames) =>
          prevGames.map((game) =>
            game.game_id === editingGameId ? { ...game, ...formData } : game
          )
        );
        Swal.fire('Success', 'Game updated successfully', 'success');
      } catch (error) {
        console.error('Error updating game:', error);
        setShowModal(false); // Close the modal before showing the error
        Swal.fire('Error', 'Failed to update game', 'error');
      }
    } else {
      try {
        const response = await axios.post('https://www.blueblocksol.com:3000/api/auth/games', formData);
        setGames((prevGames) => [...prevGames, response.data]);
        Swal.fire('Success', 'Game added successfully', 'success');
      } catch (error) {
        console.error('Error adding game:', error);
        setShowModal(false); // Close the modal before showing the error
        Swal.fire('Error', 'Failed to add game', 'error');
      }
    }

    setFormData({});
    setIsEditing(false);
    setEditingGameId(null);
    setShowModal(false);
  };

  const handleDeleteGame = async (gameID) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://www.blueblocksol.com:3000/api/auth/games/${gameID}`);
          setGames((prevGames) => prevGames.filter((game) => game.game_id !== gameID));
          Swal.fire('Deleted!', 'Your game has been deleted.', 'success');
        } catch (error) {
          console.error('Error deleting game:', error);
          Swal.fire('Error', 'Failed to delete game', 'error');
        }
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      // Handle file input for image
      setFormData({ ...formData, imageFile: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const openModal = (isEdit = false, game = {}) => {
    setIsEditing(isEdit);
    setFormData(isEdit ? game : {});
    setEditingGameId(isEdit ? game.game_id : null);
    setShowModal(true);
  };

  const columns = [
    { name: 'Game ID', selector: (row) => row.game_id, sortable: true },
    { name: 'Name', selector: (row) => row.name, sortable: true },
    { name: 'Image URL', selector: (row) => row.image_url, sortable: true },
    { name: 'Upload Date', selector: (row) => row.created_at, sortable: true },
    { name: 'Category', selector: (row) => row.category, sortable: true },
    { name: 'Description', selector: (row) => row.description },
    {
      name: 'Enable/Disable',
      cell: (row) => (
        <button className="btn btn-link p-0" onClick={() => toggleGameStatus(row.game_id)}>
          {row.status === 'Active' ? <i className="ti ti-md ti-eye"></i> : <i className="ti ti-md ti-eye-off"></i>}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button className="btn btn-link p-0 me-2" onClick={() => openModal(true, row)}>
            <i className="ti ti-md ti-pencil"></i>
          </button>
          <button className="btn btn-link p-0 text-danger" onClick={() => handleDeleteGame(row.game_id)}>
            <i className="ti ti-md ti-trash"></i>
          </button>
        </>
      ),
    },
  ];

  return (
    <Layout loading={loading}>
      {loading ? (
        <Loader />
      ) : (
        <div className="card">
          <h5 className="card-header">Games</h5>
          <div className="d-flex justify-content-end m-3">
            <button className="btn btn-success btn-sm p-1" onClick={() => openModal(false)}>
              <i className="ti ti-md ti-plus"></i>
            </button>
          </div>
          <div className="card-datatable text-nowrap">
            <DataTable
              columns={columns}
              data={games}
              pagination
              highlightOnHover
              striped
            />
          </div>

          {/* Modal for adding/updating games */}
          {showModal && (
            <div className="modal show d-block" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{isEditing ? 'Edit Game' : 'Add Game'}</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">Game Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          placeholder="Game Name"
                          value={formData.name || ''}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="image_url" className="form-label">Upload Image</label>
                        <input
                          type="file"
                          className="form-control"
                          id="image_url"
                          name="image_url"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="url_of_game" className="form-label">URL of Game</label>
                        <input
                          type="text"
                          className="form-control"
                          id="url_of_game"
                          name="url_of_game"
                          placeholder="URL of Game"
                          value={formData.url_of_game || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea
                          className="form-control"
                          id="description"
                          name="description"
                          placeholder="Description"
                          value={formData.description || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="category" className="form-label">Category</label>
                        <input
                          type="text"
                          className="form-control"
                          id="category"
                          name="category"
                          placeholder="Category"
                          value={formData.category || ''}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="status" className="form-label">Status</label>
                        <select
                          className="form-control"
                          id="status"
                          name="status"
                          value={formData.status || ''}
                          onChange={handleInputChange}
                        >
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                      <button type="submit" className="btn btn-primary">{isEditing ? 'Update' : 'Add'} Game</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default Games;
