import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import axios from 'axios';

const Users = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Fetch users data from the API
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://www.blueblocksol.com:3000/api/auth/users');
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
        Swal.fire('Error', 'Failed to fetch users from the server', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const toggleUserStatus = (userId) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.user_id === userId ? { ...user, is_active: !user.is_active } : user
      )
    );
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (isEditing) {
      try {
        await axios.put(`https://www.blueblocksol.com:3000/api/auth/users/${editingUserId}`, formData);
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.user_id === editingUserId ? { ...user, ...formData } : user
          )
        );
        Swal.fire('Success', 'User updated successfully', 'success');
      } catch (error) {
        console.error('Error updating user:', error);
        Swal.fire('Error', 'Failed to update user', 'error');
      }
    } else {
      try {
        const response = await axios.post('https://www.blueblocksol.com:3000/api/auth/users', formData);
        setUsers((prevUsers) => [...prevUsers, response.data]);
        Swal.fire('Success', 'User added successfully', 'success');
      } catch (error) {
        console.error('Error adding user:', error);
        Swal.fire('Error', 'Failed to add user', 'error');
      }
    }

    setFormData({});
    setIsEditing(false);
    setEditingUserId(null);
    setShowModal(false);
  };

  const handleDeactivateUser = async (userId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "The user will be marked as inactive!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, mark as inactive!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`https://www.blueblocksol.com:3000/api/auth/users/${userId}`);
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.user_id === userId ? { ...user, is_active: 0, updated_at: new Date().toISOString() } : user
            )
          );
          Swal.fire('Success!', 'User has been marked as inactive.', 'success');
        } catch (error) {
          console.error('Error marking user as inactive:', error);
          Swal.fire('Error', 'Failed to mark user as inactive', 'error');
        }
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const openModal = (isEdit = false, user = {}) => {
    setIsEditing(isEdit);
    setFormData(isEdit ? user : {});
    setEditingUserId(isEdit ? user.user_id : null);
    setShowModal(true);
  };

  const columns = [
    { name: 'User ID', selector: (row) => row.user_id, sortable: true },
    { name: 'First Name', selector: (row) => row.first_name, sortable: true },
    { name: 'Last Name', selector: (row) => row.last_name, sortable: true },
    { name: 'Email', selector: (row) => row.email_id, sortable: true },
    { name: 'Role', selector: (row) => row.Role, sortable: true },
    { name: 'Status', selector: (row) => (row.is_active ? 'Active' : 'Inactive'), sortable: true },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button className="btn btn-link p-0 me-2" onClick={() => openModal(true, row)}>
            <i className="ti ti-md ti-pencil"></i>
          </button>
          <button className="btn btn-link p-0 text-danger" onClick={() => handleDeactivateUser(row.user_id)}>
            <i className="ti ti-md ti-trash"></i>
          </button>
        </>
      ),
    },
  ];

  return (
    <Layout loading={loading}>
      {loading ? (
        <Loader />
      ) : (
        <div className="card">
          <h5 className="card-header">Users</h5>
          <div className="d-flex justify-content-end m-3">
            <button className="btn btn-success btn-sm p-1" onClick={() => openModal(false)}>
              <i className="ti ti-md ti-plus"></i>
            </button>
          </div>
          <div className="card-datatable text-nowrap">
            <DataTable
              columns={columns}
              data={users}
              pagination
              highlightOnHover
              striped
            />
          </div>

          {/* Modal for adding/updating users */}
          {showModal && (
            <div className="modal show d-block" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{isEditing ? 'Edit User' : 'Add User'}</h5>
                    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>
                  </div>
                  <form onSubmit={handleFormSubmit}>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label htmlFor="first_name" className="form-label">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="first_name"
                          name="first_name"
                          placeholder="First Name"
                          value={formData.first_name || ''}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="last_name" className="form-label">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="last_name"
                          name="last_name"
                          placeholder="Last Name"
                          value={formData.last_name || ''}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email_id" className="form-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="email_id"
                          name="email_id"
                          placeholder="Email"
                          value={formData.email_id || ''}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="Role" className="form-label">Role</label>
                        <select
                          className="form-control"
                          id="Role"
                          name="Role"
                          value={formData.Role || ''}
                          onChange={handleInputChange}
                        >
                          <option value="user">User</option>
                          <option value="admin">Admin</option>
                        </select>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>Close</button>
                      <button type="submit" className="btn btn-primary">{isEditing ? 'Update' : 'Add'} User</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

export default Users;
