import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout'; // Import the Layout component
import axios from 'axios'; // Import axios for API calls
import StatsCard from '../components/cards/StatsCard';

const AdminHome = () => {
  const [stats, setStats] = useState({});
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get('https://www.blueblocksol.com:3000/users/stats'); // API call to fetch stats
        setStats(response.data); // Save the fetched statistics in the state
      } catch (error) {
        setError('Error fetching statistics. Please try again later.');
        console.error('Error fetching statistics:', error.message);
      }
    };

    fetchStats();
  }, []);

  return (
    <Layout>
      <div className="row mt-3">
        {error && <p className="text-danger text-center">{error}</p>} {/* Display error message if any */}

        {/* Render StatsCard components for each statistic dynamically from fetched data */}
        <StatsCard 
          iconClass="ti ti-users ti-28px" 
          count={stats.total_users || 0} 
          title="Total Users"
          percentageChange="+10%" // You can dynamically calculate this if needed
          changeDescription="than last month"
        />
        <StatsCard 
          iconClass="ti ti-device-gamepad ti-28px" 
          count={stats.total_games || 0} 
          title="Total Games"
        />
        <StatsCard 
          iconClass="ti ti-user-check ti-28px" 
          count={stats.active_users || 0} 
          title="Active Users"
        />
        <StatsCard 
          iconClass="ti ti-user-off ti-28px" 
          count={stats.deactive_users || 0} 
          title="Deactive Users"
        />
        <StatsCard 
          iconClass="ti ti-device-gamepad-3 ti-28px" 
          count={stats.active_games || 0} 
          title="Active Games"
        />
        <StatsCard 
          iconClass="ti ti-dice-3 ti-28px" 
          count={stats.deactive_games || 0} 
          title="Deactive Games"
        />
        <StatsCard 
          iconClass="ti ti-cash ti-28px" 
          count={`$${stats.todays_deposits || 0}`} 
          title="Today's Game Investment"
        />
        <StatsCard 
          iconClass="ti ti-wallet ti-28px" 
          count={`$${stats.total_deposits || 0}`} 
          title="Total Game Investment"
        />
        <StatsCard 
          iconClass="ti ti-credit-card ti-28px" 
          count={`$${stats.total_deposits || 0}`} 
          title="Total Deposit"
        />
        <StatsCard 
          iconClass="ti ti-wallet ti-28px" 
          count={`$${stats.total_withdrawals || 0}`} 
          title="Total Withdrawal"
        />
        <StatsCard 
          iconClass="ti ti-trophy ti-28px" 
          count="$15,000" // Placeholder or real-time data if available
          title="Total Winning"
        />

        {/* Optionally, add any other components */}
        {/* <WebsiteAnalyticsSwiper /> */}
      </div>
    </Layout>
  );
};

export default AdminHome;
