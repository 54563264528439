// src/utils/jwtUtils.js
import { jwtDecode } from 'jwt-decode'; // Correct named import

export const decodeJWT = (token) => {
  try {
    const decoded = jwtDecode(token);
    const {email, firstname, lastname, id,Role,referral_code} = decoded;

    // Log the decoded data
    // console.log('Decoded JWT Data:', email);

    return {email, firstname, lastname, id, Role,referral_code};
  } catch (error) {
    console.error('Invalid token', error);
    return null;
  }
};
