import React, { useState, useEffect } from 'react';
import Sidebar from '../components/profile/Sidebar';
import DashboardCards from '../components/profile/DashboardCards';
import TransactionTable from '../components/profile/TransactionTable';
import Header from '../components/Header';
import Footer from '../components/Footer';
import DepositHistory from '../components/profile/DepositHistory';
import WithdrawHistory from '../components/profile/WithdrawHistory';
import MyReferral from '../components/profile/MyReferral'; // Import the new component
import { useNavigate } from 'react-router-dom';
import UserLayout from '../components/Layout';
import GameActivityTable from '../components/profile/GameActivityTable';
import MobileSidebar from '../components/profile/MobileSidebar'; // Import the MobileSidebar component

const ProfilePage = () => {
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const [isMobileView, setIsMobileView] = useState(false); // State to track if it's mobile view
  const navigate = useNavigate();

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 768); // Set to true if width is less than 768px
    };

    // Check window size on load
    checkMobileView();

    // Add event listener to handle window resize
    window.addEventListener('resize', checkMobileView);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleComponentChange = (component) => {
    setActiveComponent(component);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'deposit':
        return <DepositHistory />;
      case 'withdraw':
        return <WithdrawHistory />;
      case 'transactions':
        return <TransactionTable />;
      case 'gameactivity':
        return <GameActivityTable />;
      case 'MyReferral':
        return <MyReferral />;
      default:
        return <TransactionTable />;
    }
  };

  const getComponentName = () => {
    switch (activeComponent) {
      case 'dashboard':
        return 'Dashboard';
      case 'deposit':
        return 'Deposit History';
      case 'withdraw':
        return 'Withdraw History';
      case 'transactions':
        return 'Transaction Table';
      case 'MyReferral':
        return 'My Referrals';
      default:
        return 'Dashboard';
    }
  };

  return (
    <>
      <UserLayout>
        <div className="dashboard-section padding-top padding-bottom">
          <div className="container-fluid">
            <div className="row">
              {/* Left Sidebar for Desktop */}
              <div className="col-lg-3 d-none d-lg-block">
                <Sidebar onComponentChange={handleComponentChange} />

              </div>

              {/* Main Content Area */}
              <div className="col-lg-6">
                <div className="user-toggler-wrapper d-flex align-items-center d-lg-none">
                  <h4 className="title m-0">User Dashboard</h4>
                  <div className="user-toggler">
                    <i className="las la-sliders-h"></i>
                  </div>
                </div>
                {isMobileView && <MobileSidebar onComponentChange={handleComponentChange} />}

                <div className="component-spacing">
                  <DashboardCards />
                  {/* Display the name of the selected component after the cards */}
                  <h3 className="text-white mt-4">{getComponentName()}</h3>
                  {renderComponent()}
                </div>
              </div>

              {/* Right Container with Buttons */}
              <div className="col-lg-3">
                <div
                  className="card p-4 text-center"
                  style={{
                    borderRadius: '8px',
                    backgroundColor: '#4a1843',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                    border: '1px solid #5c2155',
                  }}
                >
                  <h5 className="mb-4 text-white">Actions</h5>
                  <button
                    className="btn mb-3"
                    style={{ width: '100%', backgroundColor: '#ffaa00', color: '#350b2d' }}
                    onClick={() => handleNavigate('/user/UpdateProfile')}
                  >
                    Update Profile
                  </button>
                  <button
                    className="btn mb-3"
                    style={{ width: '100%', backgroundColor: '#ffaa00', color: '#350b2d' }}
                    onClick={() => handleNavigate('/user/RequestMetacoins')}
                  >
                    Request Metacoins
                  </button>
                  <button
                    className="btn"
                    style={{ width: '100%', backgroundColor: '#ffaa00', color: '#350b2d' }}
                    onClick={() => handleNavigate('/user/WithdrawRequest')}
                  >
                    Withdraw Request
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Sidebar */}
      </UserLayout>
    </>
  );
};

export default ProfilePage;
