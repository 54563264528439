import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader'; // Import the Loader component

import '../assets/vendor/css/pages/cards-advance.css';
import AdminMobileSidebar from './AdminMobileSidebar';

const Layout = ({ children, loading }) => {
  return (
    <div className="admin">
      {loading ? (
        <Loader /> // Display loader while loading is true
      ) : (
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <Sidebar />
            <div className="layout-page">
              <Header />
              <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y container-fluid">
                  <div className="row g-6">
                    {children}
                  </div>
                </div>
                  <AdminMobileSidebar/>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Layout;
