import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './user/pages/Home';
import ProfilePage from './user/pages/ProfilePage';
import WithdrawRequest from './user/pages/WithdrawRequest';
import UpdateProfile from './user/pages/UpdateProfile';
import RequestMetacoins from './user/pages/RequestMetacoins';
import AdminHome from './admin/pages/HomePage';
import Deposit from './admin/pages/Deposit';
import Games from './admin/pages/Games';
import Users from './admin/pages/Users';
import Transactions from './admin/pages/Transactions';
import GameZone from './admin/pages/GameZone';
import Login from './user/pages/Login';
import UserGames from './user/pages/Games';

import Registration from './user/pages/Registration';
import PlayGame from './user/pages/PlayGame';

import { DarkModeProvider } from './admin/DarkModeContext'; // Import the provider
import HowItWorks from './user/pages/HowItWorks';
import CryptoRequests from './admin/pages/CryptoRequests';
import activity from './user/pages/GameActivity';
import GameActivity from './user/pages/GameActivity';
import GameActivities from './admin/pages/GameActivities';

function loadCSS(href) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = href;
  link.id = `dynamic-css-${href}`;
  document.head.appendChild(link);
}

function removeAllDynamicCSS() {
  const dynamicLinks = document.querySelectorAll('link[id^="dynamic-css-"]');
  dynamicLinks.forEach(link => link.remove());
}

const adminCSS = [
  '../admin/assets/vendor/css/rtl/core.css',
  '../admin/assets/vendor/css/rtl/theme-default.css',
  '../admin/assets/css/demo.css',
  '../admin/assets/vendor/libs/node-waves/node-waves.css',
  '../admin/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css',
  '../admin/assets/vendor/libs/typeahead-js/typeahead.css',
  '../admin/assets/vendor/libs/apex-charts/apex-charts.css',
  '../admin/assets/vendor/libs/swiper/swiper.css',
  '../admin/assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css',
  '../admin/assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css',
  '../admin/assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css'
];

const userCSS = [
  '../user/assets/css/lib/bootstrap.min.css',
  '../user/assets/css/all.min.css',
  '../user/assets/css/line-awesome.min.css',
  '../user/assets/css/lib/animate.css',
  '../user/assets/css/main.css'
];

const loadCSSFiles = (files) => {
  removeAllDynamicCSS(); // Remove any previously loaded CSS
  files.forEach(file => loadCSS(file)); // Load new CSS files
};

function AdminRoutes() {
  useEffect(() => {
    loadCSSFiles(adminCSS); // Load admin CSS files
  }, []);

  return (
    <Routes>
      <Route path="/Home" element={<AdminHome />} />
      <Route path="/Deposit" element={<Deposit />} />
      <Route path="/Games" element={<Games />} />
      <Route path="/Users" element={<Users />} />
      <Route path="/Transactions" element={<Transactions />} />
      <Route path="/GameZone" element={<GameZone />} />
      <Route path="/CryptoRequests" element={<CryptoRequests />} />
      <Route path="/GameActivities" element={<GameActivities />} />

    </Routes>
  );
}

function UserRoutes() {
  useEffect(() => {
    loadCSSFiles(userCSS); // Load user CSS files
  }, []);

  return (
    <Routes>
      <Route path="/Home" element={<Home />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/PlayGame" element={<PlayGame />} />
      <Route path="/HowItWorks" element={<HowItWorks />} />

      <Route path="/WithdrawRequest" element={<WithdrawRequest />} />
      <Route path="/UpdateProfile" element={<UpdateProfile />} />
      <Route path="/RequestMetacoins" element={<RequestMetacoins />} />
      <Route path="/Games" element={<UserGames />} />
      <Route path="/activity" element={<GameActivity />} />


    </Routes>
  );
}

function App() {
  return (
    <Routes>
      <Route exact path="/admin/*" element={<AdminRoutes />} />
        <Route exact path="/" element={<Login />} />
      <Route exact path="/user/*" element={<UserRoutes/>} />
        <Route exact path="/register" element={<Registration />} />
    </Routes>
  );
}

function AppWrapper() {
  return (
    <DarkModeProvider>
      <Router>
        <App />
      </Router>
    </DarkModeProvider>
  );
}

export default AppWrapper;
