import React, { useState, useEffect } from 'react';
import { useUser } from '../../../context/UserContext';
import Swal from 'sweetalert2';

const MobileSidebar = ({ onComponentChange }) => {
  const { user } = useUser();
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    checkMobileView();
    window.addEventListener('resize', checkMobileView);
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  if (!isMobileView || !user) return null;

  const handleCopy = () => {
    const referralUrl = `http://localhost:3000/register?referral=${user.referral_code}`;

    navigator.clipboard.writeText(referralUrl).then(() => {
      Swal.fire({
        title: 'Copied!',
        text: 'Referral link copied to clipboard!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }).catch((error) => {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to copy referral link.',
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
      console.error('Failed to copy text: ', error);
    });
  };

  return (
    <nav className="non-sticky-mobile-menu">
      <ul className="mobile-menu-list">
        <li className="mobile-menu-item">
          <a href="#" onClick={() => onComponentChange('dashboard')} className="mobile-menu-link">
            <i className="ti ti-home"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="#" onClick={() => onComponentChange('deposit')} className="mobile-menu-link">
            <i className="ti ti-wallet"></i>
            <span>Deposit History</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="#" onClick={() => onComponentChange('withdraw')} className="mobile-menu-link">
            <i className="ti ti-cash"></i>
            <span>Withdraw History</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="#" onClick={() => onComponentChange('transactions')} className="mobile-menu-link">
            <i className="ti ti-file-invoice"></i>
            <span>Transaction History</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="#" onClick={() => onComponentChange('MyReferral')} className="mobile-menu-link">
            <i className="ti ti-users"></i>
            <span>My Referrals</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="#" onClick={() => onComponentChange('profile')} className="mobile-menu-link">
            <i className="ti ti-settings"></i>
            <span>Account Settings</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="#" onClick={(e) => {
            e.preventDefault();
            handleCopy();
          }} className="mobile-menu-link">
            <i className="ti ti-copy"></i>
            <span>Copy Referral</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MobileSidebar;
