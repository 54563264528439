import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout'; // Import the Layout component
import DataTableComponent from '../components/DataTableComponent'; // Import your DataTableComponent
import Loader from '../components/Loader'; // Import the Loader component

const Deposit = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a data fetch or any processing needed on page load
    setTimeout(() => {
      setLoading(false); // Set loading to false once data is fetched or processing is complete
    }, 1000); // Simulating a 2-second load time
  }, []);

  return (
    <Layout loading={loading}> {/* Pass the loading state to the Layout component */}
      {loading ? (
        <Loader /> // Display loader when loading is true
      ) : (
        <DataTableComponent /> // Display content when loading is false
      )}
    </Layout>
  );
};

export default Deposit;
