import React, { useState } from 'react';
import DataTable from 'react-data-table-component';

// Sample data
const data = [
  {
    id: 1,
    userId: 'U001',
    amount: '$500',
    walletAddress: '0x1234567890abcdef',
    transactionID: 'TX1234567890',
    transactionDate: '2024-08-29',
    updatedAt: '2024-08-30',
    createdAt: '2024-08-28',
  },
  {
    id: 2,
    userId: 'U002',
    amount: '$1200',
    walletAddress: '0xa1b2c3d4e5f67890',
    transactionID: 'TX0987654321',
    transactionDate: '2024-08-30',
    updatedAt: '2024-09-01',
    createdAt: '2024-08-29',
  },
  {
    id: 3,
    userId: 'U003',
    amount: '$800',
    walletAddress: '0xffeeddccbbaa9988',
    transactionID: 'TX1122334455',
    transactionDate: '2024-08-31',
    updatedAt: '2024-09-02',
    createdAt: '2024-08-30',
  },
  {
    id: 4,
    userId: 'U004',
    amount: '$300',
    walletAddress: '0xabcdef1234567890',
    transactionID: 'TX6677889900',
    transactionDate: '2024-09-01',
    updatedAt: '2024-09-03',
    createdAt: '2024-08-31',
  },
  {
    id: 5,
    userId: 'U005',
    amount: '$1500',
    walletAddress: '0xdeadbeef12345678',
    transactionID: 'TX5544332211',
    transactionDate: '2024-09-02',
    updatedAt: '2024-09-04',
    createdAt: '2024-09-01',
  },
];

// Define columns
const columns = [
  {
    name: 'User ID',
    selector: row => row.userId,
    sortable: true,
  },
  {
    name: 'Amount',
    selector: row => row.amount,
    sortable: true,
  },
  {
    name: 'Wallet Address',
    selector: row => row.walletAddress,
    sortable: true,
  },
  {
    name: 'Transaction ID',
    selector: row => row.transactionID,
    sortable: true,
  },
  {
    name: 'Transaction Date',
    selector: row => row.transactionDate,
    sortable: true,
  },
  {
    name: 'Updated At',
    selector: row => row.updatedAt,
    sortable: true,
  },
  {
    name: 'Created At',
    selector: row => row.createdAt,
    sortable: true,
  },
];

const DataTableComponent = () => {
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    const filtered = data.filter(item =>
      item.userId.toLowerCase().includes(value) ||
      item.amount.toLowerCase().includes(value) ||
      item.walletAddress.toLowerCase().includes(value) ||
      item.transactionID.toLowerCase().includes(value) ||
      item.transactionDate.toLowerCase().includes(value) ||
      item.updatedAt.toLowerCase().includes(value) ||
      item.createdAt.toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  return (
    <div className="card">
      <h5 className="card-header">Deposit</h5>
      <div className="card-datatable text-nowrap">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <label className="me-2">Search:</label>
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search..."
              value={searchText}
              onChange={handleSearch}
            />
          </div>
        </div>
        <DataTable
          columns={columns}
          data={filteredData}
          pagination
          highlightOnHover
          responsive
          defaultSortFieldId={1}
        />
      </div>
    </div>
  );
};

export default DataTableComponent;
