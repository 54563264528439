import React from 'react';

const GameList = () => {
  // Sample data for the games
  const games = [
    { id: 1, title: 'Roulette', image: 'assets/images/game/item2.png', investLimit: '$10.49 - $1,000' },
    { id: 2, title: 'Zero To Ninty', image: 'assets/images/game/item1.png', investLimit: '$10.49 - $1,000' },
    { id: 3, title: 'Number Buy', image: 'assets/images/game/item3.png', investLimit: '$10.49 - $1,000' },
    { id: 4, title: 'Roulette', image: 'assets/images/game/item4.png', investLimit: '$10.49 - $1,000' },
    { id: 5, title: 'Card Game', image: 'assets/images/game/item5.png', investLimit: '$10.49 - $1,000' },
    { id: 6, title: 'Dice Rolling', image: 'assets/images/game/item6.png', investLimit: '$10.49 - $1,000' },
    // Add more games as needed
  ];

  return (
    <section
      className="game-section padding-top padding-bottom bg_img"
      style={{ backgroundImage: "url('assets/images/game/bg3.jpg')" }}
    >
      <div className="container">
        <div className="row gy-4 justify-content-center">
          {games.map((game) => (
            <div key={game.id} className="col-lg-4 col-xl-3 col-md-6 col-sm-6">
              <div className="game-item">
                <div className="game-inner">
                  <div className="game-item__thumb">
                    <img src={game.image} alt={game.title} />
                  </div>
                  <div className="game-item__content">
                    <h4 className="title">{game.title}</h4>
                    <p className="invest-info">Invest Limit</p>
                    <p className="invest-amount">{game.investLimit}</p>
                    <a href="/user/PlayGame" className="cmn--btn active btn--md radius-0">Play Now</a>
                  </div>
                </div>
                <div className="ball"></div>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="row gy-4 justify-content-center">
        <ul className="pagination mt-40">
          <li className="page-item disabled">
            <a href="#0" className="page-link">
              <i className="las la-arrow-left"></i>
            </a>
          </li>
          <li className="page-item active">
            <a href="#0" className="page-link">01</a>
          </li>
          <li className="page-item">
            <a href="#0" className="page-link">02</a>
          </li>
          <li className="page-item">
            <a href="#0" className="page-link">03</a>
          </li>
          <li className="page-item">
            <a href="#0" className="page-link">
              <i className="las la-arrow-right"></i>
            </a>
          </li>
        </ul>
</div> */}
        {/* Pagination */}
       
      </div>
    </section>
  );
};

export default GameList;
