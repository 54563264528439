import React from 'react';
import UserLayout from '../components/Layout';
import GameActivityTable from '../components/profile/GameActivityTable';

const GameActivity = () => {
  return (
    <UserLayout>
      <div className="d-flex justify-content-center align-items-center mt-40 mb-40">
        <GameActivityTable />
      </div>
    </UserLayout>
  );
};

export default GameActivity;
