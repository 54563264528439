import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Container, Box, Typography, TextField, Button, Checkbox, Link, InputAdornment, Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import { decodeJWT } from '../../utils/jwtUtils'; // Import the utility function

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('https://www.blueblocksol.com:3000/api/auth/login', { 
        email: username, 
        password 
      });

      const token = response.data.token;
      localStorage.setItem('token', token);

      const decodedToken = decodeJWT(token); // Use the imported utility function
      if (!decodedToken) {
        throw new Error('Failed to decode token'); // Handle decoding failure
      }
      
      const userRole = decodedToken.Role; // Assuming the role is stored as 'role' in the token
console.log(decodedToken);

      if (userRole === 'admin') {
        // Show modal to ask where to navigate
        Swal.fire({
          title: 'Admin Access Detected',
          text: 'Would you like to go to the admin dashboard or the user dashboard?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Admin Dashboard',
          cancelButtonText: 'User Dashboard',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/admin/Home'); // Navigate to admin page
          } else {
            navigate('/user/Home'); // Navigate to user page
          }
        });
      } else {
        Swal.fire({
          title: 'Login Successful!',
          text: 'You have been logged in successfully. Redirecting to the home page...',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });

        navigate('/user/Home'); // Redirect to the user home page
      }
    } catch (error) {
      setError('Invalid username or password');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundImage: 'url(user/assets/images/account/bg.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        color: '#fff',
        fontFamily: 'Roboto, sans-serif',
        padding: '20px',
        backgroundColor: '#19082b',
      }}
    >
      <Grid container spacing={2} sx={{ width: '100%', maxWidth: '1200px', margin: '0 auto' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              width: '100%',
              maxWidth: { xs: '400px', md: '550px' },
              padding: '30px',
              backgroundColor: 'rgba(25, 8, 43, 0.85)',
              borderRadius: '15px',
              boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <Box sx={{ textAlign: 'center', marginBottom: '20px' }}>
              <Typography variant="h4" sx={{ color: '#ffb400', fontWeight: 'bold' }}>
                Sign In
              </Typography>
            </Box>
            {error && <Typography variant="body2" color="error" sx={{ textAlign: 'center' }}>{error}</Typography>}
            <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
              {/* Form fields */}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                placeholder="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon style={{ color: '#ffb400' }} />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: 'transparent', color: '#fff' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#000',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ffb400',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ffb400',
                    },
                  },
                  marginBottom: '20px',
                }}
              />
              {/* Password Field */}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                placeholder="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon style={{ color: '#ffb400' }} />
                    </InputAdornment>
                  ),
                  style: { backgroundColor: 'transparent', color: '#fff' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#000',
                    },
                    '&:hover fieldset': {
                      borderColor: '#ffb400',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#ffb400',
                    },
                  },
                  marginBottom: '20px',
                }}
              />
              {/* Remember Me and Forgot Password */}
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox value="remember" color="default" sx={{ color: '#ffb400' }} />
                  <Typography variant="body2" sx={{ color: '#fff' }}>Remember me</Typography>
                </Box>
                <Link href="/" sx={{ color: '#ffb400', textDecoration: 'none', fontSize: '14px' }}>
                  Forgot Password?
                </Link>
              </Box>
              {/* Submit Button */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: '#ffb400',
                  color: '#fff',
                  borderRadius: '25px',
                  padding: '10px 0',
                  fontSize: '16px',
                  textTransform: 'none',
                  boxShadow: '0 4px 10px rgba(255, 180, 0, 0.5)',
                  marginBottom: '20px',
                }}
                disabled={loading}
              >
                {loading ? 'Signing In...' : 'Sign In'}
              </Button>
            </form>
            {/* Footer Links */}
            <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
              <Typography variant="body2" sx={{ color: '#fff' }}>Don't have an Account yet?</Typography>
              <Link href="/register" sx={{ color: '#ffb400', textDecoration: 'none', fontWeight: 'bold' }}>
                Sign Up
              </Link>
            </Box>
          </Box>
        </Grid>
        {/* Welcome Section */}
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              width: '100%',
              maxWidth: { xs: '400px', md: '550px' },
              padding: '30px',
              backgroundColor: 'rgba(25, 8, 43, 0.85)',
              borderRadius: '15px',
              textAlign: 'center',
            }}
          >
            <Typography variant="h2" sx={{ fontSize: { xs: '24px', md: '36px' }, fontWeight: 'bold', color: '#ffb400' }}>
              Welcome to Casinio
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '16px', md: '18px' }, marginTop: '10px', color: '#fff' }}>
              Sign in your Account. Atque, fuga sapiente, doloribus qui enim tempora?
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '20px' }}>
              Already have an account? <Link href="/register" sx={{ color: '#ffb400', textDecoration: 'none' }}>signup</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Login;
