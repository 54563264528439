import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Container, Box, Typography, TextField, Button, Link, InputAdornment, Grid } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

function Registration() {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const referralCodeFromURL = searchParams.get('referral') || 'MetagmSr';

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    dob: '',
    referralCode: referralCodeFromURL,
    otp: '', // Added field for OTP
    walletAddress: '', // Added field for Wallet Address
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false); // Track OTP sent status
  const [otpVerified, setOtpVerified] = useState(false); // Track OTP verification status
  const [showVerifyButton, setShowVerifyButton] = useState(false); // Track display of verify button

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleEmailBlur = () => {
    setShowVerifyButton(true);
  };

  const handleSendOtp = async () => {
    if (formData.email) {
      try {
        await axios.post('https://www.blueblocksol.com:3000/api/send-otp', { email: formData.email });
        Swal.fire('Success', 'OTP sent to your email. Please check your inbox.', 'success');
        setOtpSent(true);
      } catch (err) {
        Swal.fire('Error', 'Failed to send OTP. Please try again.', 'error');
      }
    }
  };

  const handleOtpVerification = async () => {
    if (formData.otp) {
      try {
        const response = await axios.post('https://www.blueblocksol.com:3000/api/verify-otp', { email: formData.email, otp: formData.otp });
        if (response.data.success) {
          Swal.fire('Success', 'OTP verified successfully.', 'success').then(() => {
            setOtpVerified(true); // Set OTP verified to true after success
          });
        } else {
          Swal.fire('Error', 'Invalid OTP. Please try again.', 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to verify OTP.', 'error');
      }
    } else {
      Swal.fire('Error', 'Please enter the OTP.', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!otpVerified) {
      setError('Please verify your email first.');
      setLoading(false);
      return;
    }

    if (calculateAge(formData.dob) < 18) {
      setError('You must be at least 18 years old.');
      setLoading(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      // Register the user
      const response = await axios.post('https://www.blueblocksol.com:3000/api/auth/register', formData);

      // After successful registration, transfer tokens
      const tokenResponse = await axios.post('https://www.blueblocksol.com:3000/api/crypto/request-tokens', {
        walletAddress: formData.walletAddress,
        tokenAmount: 10000,
        email: formData.email
      });

      // If token transfer is successful, proceed with registration success
      Swal.fire({
        title: 'Registration Successful!',
        text: `You have been registered successfully. 10,000 MTG has been transferred to your wallet. Transaction Hash: ${tokenResponse.data.transactionHash}`,
        icon: 'success',
        timer: 4000,
        showConfirmButton: false,
      });

      localStorage.setItem('token', response.data.token);
      navigate('/');
    } catch (err) {
      setError(err.response?.data?.message || 'Registration or token transfer failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundImage: 'url(/user/assets/images/account/bg.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        color: '#fff',
        fontFamily: 'Roboto, sans-serif',
        padding: '20px',
      }}
    >
      <Grid container spacing={2} sx={{ width: '100%', maxWidth: '1400px', margin: '0 auto' }}>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              width: '100%',
              maxWidth: { xs: '380px', md: '550px' },
              padding: '30px',
              backgroundColor: 'rgba(25, 8, 43, 0.95)',
              borderRadius: '15px',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: '25px', color: '#ffb400', fontWeight: 'bold' }}>
              Sign Up
            </Typography>
            {error && <Typography variant="body2" color="error" sx={{ textAlign: 'center', marginBottom: '15px' }}>{error}</Typography>}
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="phoneNumber"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    placeholder="Email"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleEmailBlur}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
                {showVerifyButton && !otpSent && !otpVerified && (
                  <Grid item xs={12} md={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleSendOtp}
                      sx={{
                        backgroundColor: '#ffb400',
                        color: '#fff',
                        marginTop: '10px',
                        '&:hover': {
                          backgroundColor: '#e69500',
                        },
                      }}
                    >
                      Verify Email
                    </Button>
                  </Grid>
                )}
                {otpSent && !otpVerified && (
                  <Grid item xs={12} md={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="otp"
                      placeholder="Enter OTP"
                      name="otp"
                      value={formData.otp}
                      onChange={handleChange}
                      InputProps={{
                        style: { color: '#fff' },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: '#000',
                          },
                          '&:hover fieldset': {
                            borderColor: '#ffb400',
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: '#ffb400',
                          },
                        },
                        backgroundColor: 'transparent',
                      }}
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleOtpVerification}
                      sx={{
                        backgroundColor: '#ffb400',
                        color: '#fff',
                        marginTop: '10px',
                        '&:hover': {
                          backgroundColor: '#e69500',
                        },
                      }}
                    >
                      Verify OTP
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="walletAddress"
                    placeholder="Wallet Address"
                    name="walletAddress"
                    value={formData.walletAddress}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="dob"
                    placeholder="Date of Birth"
                    type="date"
                    id="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CalendarTodayIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    placeholder="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    value={formData.password}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000',
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    name="referralCode"
                    placeholder="Referral Code"
                    id="referralCode"
                    value={formData.referralCode}
                    disabled
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon style={{ color: '#ffb400' }} />
                        </InputAdornment>
                      ),
                      style: { color: '#fff' }, // Ensure text color is white
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#000', // Border color
                        },
                        '&:hover fieldset': {
                          borderColor: '#ffb400',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#ffb400',
                        },
                      },
                      '& .Mui-disabled': {
                        opacity: 1, // Override opacity for disabled state
                        '-webkit-text-fill-color': '#fff', // Override the text fill color to white
                      },
                      backgroundColor: 'transparent',
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  backgroundColor: '#ffb400',
                  color: '#fff',
                  marginTop: '30px',
                  borderRadius: '50px',
                  padding: '12px 0',
                  fontSize: '18px',
                  textTransform: 'none',
                  boxShadow: '0 4px 10px rgba(255, 180, 0, 0.5)',
                  '&:hover': {
                    backgroundColor: '#e69500',
                  },
                }}
                disabled={loading}
              >
                {loading ? 'Registering...' : 'Sign Up'}
              </Button>
            </form>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{
              width: '100%',
              maxWidth: { xs: '380px', md: '550px' },
              padding: '30px',
              backgroundColor: 'rgba(25, 8, 43, 0.95)',
              borderRadius: '15px',
              textAlign: 'center',
              boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Typography variant="h2" sx={{ fontSize: { xs: '24px', md: '36px' }, fontWeight: 'bold', color: '#ffb400' }}>
              Welcome to Casinio
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '16px', md: '18px' }, marginTop: '10px', color: '#fff' }}>
              Sign in to your Account. Atque, fuga sapiente, doloribus qui enim tempora?
            </Typography>
            <Typography variant="body2" sx={{ marginTop: '20px', color: '#fff' }}>
              Already have an Account? <Link href="/login" sx={{ color: '#ffb400', textDecoration: 'none' }}>Sign In</Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default Registration;
