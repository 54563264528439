import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlayCircle, faGamepad, faQuestionCircle, faUserCircle, faPhone } from '@fortawesome/free-solid-svg-icons';

const MobileMenu = () => {
  const [isMobileView, setIsMobileView] = useState(false); // State to track if the view is mobile

  useEffect(() => {
    // Function to check window size
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 768); // Set to true if width is less than 768px
    };

    // Check window size on load
    checkMobileView();

    // Add event listener to handle window resize
    window.addEventListener('resize', checkMobileView);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  // Conditionally render the mobile menu if in mobile view
  if (!isMobileView) return null;

  return (
    <nav className="mobile-menu">
      <ul className="mobile-menu-list">
        <li className="mobile-menu-item">
          <a href="/user/Home" className="mobile-menu-link">
            <FontAwesomeIcon icon={faHome} />
            <span>Home</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="/user/HowItWorks" className="mobile-menu-link">
            <FontAwesomeIcon icon={faPlayCircle} />
            <span>How It Works</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="/user/games" className="mobile-menu-link">
            <FontAwesomeIcon icon={faGamepad} />
            <span>Games</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="/user/faq" className="mobile-menu-link">
            <FontAwesomeIcon icon={faQuestionCircle} />
            <span>Faq</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="/user/profile" className="mobile-menu-link">
            <FontAwesomeIcon icon={faUserCircle} />
            <span>User Dashboard</span>
          </a>
        </li>
        <li className="mobile-menu-item">
          <a href="/user/" className="mobile-menu-link">
            <FontAwesomeIcon icon={faPhone} />
            <span>Contact</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default MobileMenu;
