import React from 'react';
import Layout from '../components/Layout'; // Import the Layout component
import GameComponent from '../components/GameComponent';







const GameZone = () => {

  
  return (
   
          <Layout>
          <div className="row mt-3">
       <GameComponent/>
        </div>
          </Layout>
        
  );
};

export default GameZone;
