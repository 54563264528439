import React from 'react';
import { useUser } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const { user, setUser } = useUser(); // Access user object and setUser function from context
  const navigate = useNavigate(); // Call useNavigate hook directly inside the component

  const userAvatar = "./assets/images/account/user.png"; // Replace with the actual path to the user's avatar image

  const handleLogout = () => {
    // Clear the user state
    setUser(null);

    // Remove the token from local storage
    localStorage.removeItem('token');

    // Redirect to the home page
    navigate('/');

    console.log('User logged out successfully.');
  };

  // If user is null or not loaded yet, render a loading or fallback UI
  // if (!user) {
  //   return <div>Loading...</div>;
  // }

  return (
    <div className="header">
      <div className="container">
        <div className="header-bottom">
          <div className="header-bottom-area align-items-center d-flex justify-content-between">
            <div className="logo">
              <a href="/"><img src={`${process.env.PUBLIC_URL}/metagame.png`} alt="logo" /></a>
            </div>
            <ul className="menu d-flex align-items-center">
              <li><a href="/user/Home">Home</a></li>
              <li><a href="/user/HowItWorks">How It Works</a></li>
              <li><a href="/user/games">Games <span className="badge badge--sm badge--base text-dark">NEW</span></a></li>
              {/* <li><a href="/user/activity">activity</a></li> */}
              <li><a href="/user/profile">User Dashboard</a></li>
              <li><a href="/user/">Contact</a></li>
            </ul>
            <div className="header-trigger-wrapper d-flex align-items-center">
              <div className="profile d-flex align-items-center" style={{ cursor: 'pointer' }}>
                <img src={userAvatar} alt="User Avatar" className="profile-avatar" style={{ width: '35px', height: '35px', borderRadius: '50%', marginRight: '10px' }} />
                {/* Logout Icon */}
                <FontAwesomeIcon 
                  icon={faSignOutAlt} 
                  size="lg" 
                  color="white" 
                  style={{ marginLeft: '10px', cursor: 'pointer' }} 
                  onClick={handleLogout}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
