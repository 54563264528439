import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import Loader from '../components/Loader';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import axios from 'axios';

const Transactions = () => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);

  // Fetch transactions data from the API
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('https://www.blueblocksol.com:3000/api/auth/transactions');
        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        Swal.fire('Error', 'Failed to fetch transactions from the server', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const columns = [
    { name: 'Transaction ID', selector: (row) => row.transaction_id, sortable: true },
    { name: 'User ID', selector: (row) => row.user_id, sortable: true },
    { name: 'Transaction Type', selector: (row) => row.transaction_type, sortable: true },
    { name: 'Amount', selector: (row) => row.amount, sortable: true },
    { name: 'Transaction Date', selector: (row) => row.transaction_date, sortable: true },
    { name: 'Status', selector: (row) => row.status, sortable: true },
  ];

  return (
    <Layout loading={loading}>
      {loading ? (
        <Loader />
      ) : (
        <div className="card">
          <h5 className="card-header">Transactions</h5>
          <div className="card-datatable text-nowrap">
            <DataTable
              columns={columns}
              data={transactions}
              pagination
              highlightOnHover
              striped
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Transactions;
