import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AdminMobileSidebar = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const checkMobileView = () => {
      setIsMobileView(window.innerWidth < 768); // Check if the view is mobile
    };

    checkMobileView();
    window.addEventListener('resize', checkMobileView);
    return () => window.removeEventListener('resize', checkMobileView);
  }, []);

  // Render the mobile sidebar only when in mobile view
  if (!isMobileView) return null;

  return (
    <nav className="admin-mobile-sidebar fixed-bottom bg-white p-2 shadow">
      <ul className="nav d-flex justify-content-around">
        <li className="nav-item">
          <Link to="/admin/Home" className="nav-link text-dark">
            <i className="ti ti-smart-home"></i>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/Deposit" className="nav-link text-dark">
            <i className="ti ti-wallet"></i>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/Games" className="nav-link text-dark">
            <i className="ti ti-device-gamepad-3"></i>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/Users" className="nav-link text-dark">
            <i className="ti ti-users"></i>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/CryptoRequests" className="nav-link text-dark">
            <i className="ti ti-coin"></i>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/Transactions" className="nav-link text-dark">
            <i className="ti ti-file-invoice"></i>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/GameActivities" className="nav-link text-dark">
            <i className="ti ti-dice-3"></i>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default AdminMobileSidebar;
