import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { useDarkMode } from '../DarkModeContext'; // Import the context hook

const Header = () => {
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const navigate = useNavigate(); // Correctly use the hook inside the component

  const [openDropdown, setOpenDropdown] = useState(null);

  const handleToggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleLogout = () => {
    // Perform logout logic
    localStorage.removeItem('token'); // Remove token or any authentication data
    // Navigate to the home page after logout
    navigate('/');
  };
  return (
    <nav
      className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme container-fluid"
      id="layout-navbar"
    >
      <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
        <button className="nav-item nav-link px-0 me-xl-4 btn btn-link">
          <i className="ti ti-menu-2 ti-md"></i>
        </button>
      </div>

      <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
        <div className="navbar-nav align-items-center">
          <div className="nav-item navbar-search-wrapper mb-0">
            <button className="nav-item nav-link search-toggler d-flex align-items-center px-0 btn btn-link">
              <i className="ti ti-search ti-md me-2 me-lg-4 ti-lg"></i>
              <span className="d-none d-md-inline-block text-muted fw-normal">Search (Ctrl+/)</span>
            </button>
          </div>
        </div>

        <ul className="navbar-nav flex-row align-items-center ms-auto">
          {/* Style Switcher Dropdown */}
          <li className={`nav-item dropdown-style-switcher dropdown ${openDropdown === 'styles' ? 'show' : ''}`}>
          <button className="dropdown-item" data-theme="dark" onClick={toggleDarkMode}>
                <span className="align-middle">
                  <i className="ti ti-moon-stars ti-md me-3"></i>
                </span>
              </button>
           
          </li>

          {/* Shortcuts Dropdown */}
          <li className={`nav-item dropdown-shortcuts navbar-dropdown dropdown ${openDropdown === 'shortcuts' ? 'show' : ''}`}>
            <button
              className="nav-link btn btn-text-secondary btn-icon rounded-pill btn-icon dropdown-toggle hide-arrow"
              onClick={() => handleToggleDropdown('shortcuts')}
            >
              <i className="ti ti-layout-grid-add ti-md"></i>
            </button>
            <div className={`dropdown-menu dropdown-menu-end p-0 ${openDropdown === 'shortcuts' ? 'show' : ''}`}>
              <div className="dropdown-menu-header border-bottom">
                <div className="dropdown-header d-flex align-items-center py-3">
                  <h6 className="mb-0 me-auto">Shortcuts</h6>
                  <button
                    className="btn btn-text-secondary rounded-pill btn-icon dropdown-shortcuts-add"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Add shortcuts"
                  >
                    <i className="ti ti-plus text-heading"></i>
                  </button>
                </div>
              </div>
              {/* <div className="dropdown-shortcuts-list scrollable-container">
                <div className="row row-bordered overflow-visible g-0">
                  <div className="dropdown-shortcuts-item col">
                    <span className="dropdown-shortcuts-icon rounded-circle mb-3">
                      <i className="ti ti-calendar ti-26px text-heading"></i>
                    </span>
                    <a href="app-calendar.html" className="stretched-link">Calendar</a>
                    <small>Appointments</small>
                  </div>
                  <div className="dropdown-shortcuts-item col">
                    <span className="dropdown-shortcuts-icon rounded-circle mb-3">
                      <i className="ti ti-file-dollar ti-26px text-heading"></i>
                    </span>
                    <a href="app-invoice-list.html" className="stretched-link">Invoice App</a>
                    <small>Manage Accounts</small>
                  </div>
                </div>
              </div> */}
            </div>
          </li>

          {/* Notifications Dropdown */}
          <li className={`nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-2 ${openDropdown === 'notifications' ? 'show' : ''}`}>
            <button
              className="nav-link btn btn-text-secondary btn-icon rounded-pill dropdown-toggle hide-arrow"
              onClick={() => handleToggleDropdown('notifications')}
            >
              <span className="position-relative">
                <i className="ti ti-bell ti-md"></i>
                <span className="badge rounded-pill bg-danger badge-dot badge-notifications border"></span>
              </span>
            </button>
            <ul className={`dropdown-menu dropdown-menu-end p-0 ${openDropdown === 'notifications' ? 'show' : ''}`}>
              <li className="dropdown-menu-header border-bottom">
                <div className="dropdown-header d-flex align-items-center py-3">
                  <h6 className="mb-0 me-auto">Notification</h6>
                  <div className="d-flex align-items-center h6 mb-0">
                    <span className="badge bg-label-primary me-2">8 New</span>
                    <button
                      className="btn btn-text-secondary rounded-pill btn-icon dropdown-notifications-all"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Mark all as read"
                    >
                      <i className="ti ti-mail-opened text-heading"></i>
                    </button>
                  </div>
                </div>
              </li>
              <li className="dropdown-notifications-list scrollable-container">
                <ul className="list-group list-group-flush">
                  {/* Notification items can be dynamically generated here */}
                </ul>
              </li>
              <li className="border-top">
                <div className="d-grid p-4">
                  <button className="btn btn-primary btn-sm d-flex">
                    <small className="align-middle">View all notifications</small>
                  </button>
                </div>
              </li>
            </ul>
          </li>

          {/* User Dropdown */}
          <li className={`nav-item navbar-dropdown dropdown-user dropdown ${openDropdown === 'user' ? 'show' : ''}`}>
            <button
              className="nav-link dropdown-toggle hide-arrow p-0"
              onClick={() => handleToggleDropdown('user')}
            >
              <div className="avatar avatar-online">
                <img
                  src="./admin/assets/img/avatars/1.png"
                  alt="User Avatar"
                  className="rounded-circle"
                />
              </div>
            </button>
            <ul className={`dropdown-menu dropdown-menu-end ${openDropdown === 'user' ? 'show' : ''}`}>
              <li>
                <a className="dropdown-item mt-0" href="pages-account-settings-account.html">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                      <div className="avatar avatar-online">
                        <img
                          src="./admin/assets/img/avatars/1.png"
                          alt="User Avatar"
                          className="rounded-circle"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-0">John Doe</h6>
                      <small className="text-muted">Admin</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div className="d-grid px-2 pt-2 pb-1">
                <button
                className="btn btn-sm btn-danger d-flex"
                onClick={handleLogout} // Call handleLogout on button click
              >
                <small className="align-middle">Logout</small>
                <i className="ti ti-logout ms-2 ti-14px"></i>
              </button>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <div className="navbar-search-wrapper search-input-wrapper d-none">
        <input
          type="text"
          className="form-control search-input container-xxl border-0"
          placeholder="Search..."
          aria-label="Search..."
        />
        <button className="ti ti-x search-toggler cursor-pointer btn btn-link"></button>
      </div>
    </nav>
  );
};

export default Header;
