import React from 'react';
import UserLayout from '../components/Layout';
import GameComponent from '../../admin/components/GameComponent';

const PlayGame = () => {
  return (
    <UserLayout>
      <div className="d-flex justify-content-center align-items-center mt-40 mb-40">
        <GameComponent />
      </div>
    </UserLayout>
  );
};

export default PlayGame;
