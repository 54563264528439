import React from 'react';
import { useUser } from '../../../context/UserContext';
import Swal from 'sweetalert2'; // Import SweetAlert2

const Sidebar = ({ onComponentChange }) => {
  const { user } = useUser(); // Access user object from context

  // If user is null or not loaded yet, render a loading or fallback UI
  if (!user) {
    return <div>Loading...</div>;
  }

  // Function to copy the referral link to the clipboard
  const handleCopy = () => {
    const referralUrl = `http://localhost:3000/register?referral=${user.referral_code}`;

    navigator.clipboard.writeText(referralUrl).then(() => {
      Swal.fire({
        title: 'Copied!',
        text: 'Referral link copied to clipboard!',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    }).catch((error) => {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to copy referral link.',
        icon: 'error',
        confirmButtonText: 'Try Again',
      });
      console.error('Failed to copy text: ', error);
    });
  };

  return (
    <div className="dashboard-sidebar">
      <div className="close-dashboard d-lg-none">
        <i className="las la-times"></i>
      </div>
      <div className="dashboard-user">
        <div className="user-thumb">
          <img src="assets/images/top/item1.png" alt="dashboard" />
        </div>
        <div className="user-content">
          <span className="fs-sm">Welcome</span>
          <h5 className="name text-white">{user.email}</h5>
          <h5 className="name text-white">{user.referral_code}</h5>
          {/* Link that copies to clipboard on click */}
          <a 
            href="#" 
            className="name text-white"
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor click behavior
              handleCopy(); // Call the copy function
            }}
          >
            {user.referral_code} (Click to Copy)
          </a>

          <ul className="user-option">
            <li><a href="#"><i className="las la-bell"></i></a></li>
            <li><a href="#"><i className="las la-pen"></i></a></li>
            <li><a href="#"><i className="las la-envelope"></i></a></li>
          </ul>
        </div>
      </div>
      <ul className="user-dashboard-tab">
        <li><a href="#" onClick={() => onComponentChange('dashboard')} className="custom-link">Dashboard</a></li>
        <li><a href="#" onClick={() => onComponentChange('deposit')} className='custom-link'>Deposit History</a></li>
        <li><a href="#" onClick={() => onComponentChange('withdraw')} className='custom-link'>Withdraw History</a></li>
        <li><a href="#" onClick={() => onComponentChange('transactions')} className='custom-link'>Transaction History</a></li>
        <li><a href="#" onClick={() => onComponentChange('MyReferral')} className='custom-link'>My Referrals</a></li>
        <li><a href="#" onClick={() => onComponentChange('gameactivity')} className='custom-link'>Game History</a></li>
        <li><a href="#" onClick={() => onComponentChange('profile')} className='custom-link'>Account Settings</a></li>
        <li><a href="#" onClick={() => onComponentChange('security')} className='custom-link'>Security Settings</a></li>
        <li><a href="#" onClick={() => onComponentChange('signout')} className='custom-link'>Sign Out</a></li>
      </ul>
    </div>
  );
};

export default Sidebar;
