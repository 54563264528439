import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

const GameActivityTable = () => {
  const [gameActivities, setGameActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch game activity data from the API
  useEffect(() => {
    // Retrieve the token from localStorage or wherever it's stored
    const token = localStorage.getItem('token'); // Make sure to replace this with your token retrieval logic
  
    axios
      .get('https://www.blueblocksol.com:3000/api/game/user-game-activities', {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      })
      .then((response) => {
        // Process and format the fetched data
        const activities = response.data.map((activity) => ({
          activityId: activity.activity_id,
          userId: activity.user_id,
          gameName: activity.game_name,
          betAmount: `$${parseFloat(activity.bet_amount).toFixed(2)}`,
          winAmount: `$${parseFloat(activity.win_amount).toFixed(2)}`,
          lossAmount: `$${parseFloat(activity.loss_amount).toFixed(2)}`,
          result: activity.result,
          playDate: new Date(activity.play_date).toLocaleString('en-US', {
            dateStyle: 'medium',
            timeStyle: 'short',
          }),
        }));
        setGameActivities(activities);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching game activities:', error);
        setLoading(false);
      });
  }, []);
  
  // Define columns for the DataTable
  const columns = [
    {
      name: 'Activity ID',
      selector: (row) => `#${row.activityId}`,
      sortable: true,
      style: { color: '#ff8c00' },
    },
    // {
    //   name: 'User ID',
    //   selector: (row) => row.userId,
    //   sortable: true,
    // },
    {
      name: 'Game Name',
      selector: (row) => row.gameName,
      sortable: true,
    },
    {
      name: 'Bet Amount',
      selector: (row) => row.betAmount,
      sortable: true,
      style: { color: '#ff8c00' },
    },
    {
      name: 'Win Amount',
      selector: (row) => row.winAmount,
      sortable: true,
    },
    {
      name: 'Loss Amount',
      selector: (row) => row.lossAmount,
      sortable: true,
    },
    {
      name: 'Result',
      selector: (row) => row.result,
      sortable: true,
      style: { fontWeight: 'bold' },
    },
    {
      name: 'Play Date',
      selector: (row) => row.playDate,
      sortable: true,
    },
  ];

  return (
    <div className="table--responsive--md mt-5">
      <DataTable
        columns={columns}
        data={gameActivities}
        progressPending={loading}
        customStyles={{
          header: {
            style: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
          },
          rows: {
            style: {
              backgroundColor: '#350b2d',
              color: 'white',
              '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#ff8c00',
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
            hoverStyle: {
              backgroundColor: '#350b2d',
              color: 'white',
            },
          },
          headCells: {
            style: {
              backgroundColor: '#ff8c00',
              color: '#350b2d',
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              color: 'white',
            },
          },
        }}
        pagination
      />
    </div>
  );
};

export default GameActivityTable;
