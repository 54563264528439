// DarkModeContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const DarkModeContext = createContext();

export const useDarkMode = () => useContext(DarkModeContext);

export const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  useEffect(() => {
    const darkModeCSS = '../admin/assets/vendor/css/rtl/core-dark.css';
    const themeDefaultDarkCSS = '../admin/assets/vendor/css/rtl/theme-default-dark.css';

    const loadCSS = (href, id) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = href;
      link.id = id;
      document.head.appendChild(link);
    };

    const removeCSS = (id) => {
      const link = document.getElementById(id);
      if (link) link.remove();
    };

    if (isDarkMode) {
      loadCSS(darkModeCSS, 'dark-mode-css');
      loadCSS(themeDefaultDarkCSS, 'theme-default-dark-css');
    } else {
      removeCSS('dark-mode-css');
      removeCSS('theme-default-dark-css');
    }
  }, [isDarkMode]);

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};
