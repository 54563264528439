import React, { useEffect, useState } from 'react';

const GetStarted = () => {
  const [scrollPercent, setScrollPercent] = useState(0);

  // Function to calculate the scroll percentage
  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const scrollPercent = (scrollTop / docHeight) * 100;
    setScrollPercent(scrollPercent);
  };

  // Attach scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Generate a dynamic gradient color based on the scroll percentage
  const dynamicColor = `linear-gradient(to bottom, #f7931e ${scrollPercent}%, #3b0d60 ${scrollPercent}%)`;

  return (
    <div className="get-started-section position-relative p-4 text-white">
      {/* Background Image and Color Overlay */}
      <div
        className="background-overlay"
        style={{
          backgroundImage: `
            linear-gradient(-144deg, rgba(6, 7, 13, 0.8) 0%, rgba(27, 11, 33, 0.8) 52.52%, rgba(6, 16, 38, 0.8) 100%),
            url(assets/images/banner/8y_jPvGDa8C_poster.jpg)
          `,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></div>

      <h2 className="text-center mb-4 position-relative" style={{ zIndex: 2 }}>How to Get Started</h2>
      <div className="d-flex flex-column align-items-center position-relative" style={{ zIndex: 2 }}>
        {/* Timeline Line with dynamic color */}
        <div
          className="position-absolute top-0 start-50 translate-middle-x"
          style={{
            width: '4px', // Adjust the width for better visibility
            height: '100%',
            background: dynamicColor,
            zIndex: 1, // Ensures line is below the cards
          }}
        ></div>

        {/* Step 1 - Left Side */}
        <div className="d-flex w-100 mb-4 align-items-center">
          <div className="col-6 text-end pe-4">
            <div className="bg-[#5B174E] text-white shadow-lg h-100 d-flex flex-column justify-content-between p-3 ms-auto border-0">
              <h3 className="card-title fw-bold">Sign Up</h3>
              <p>Create your account in just a few clicks.</p>
              <a href="/register">
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded text-sm w-20">
                  Sign Up
                </button>
              </a>
            </div>
          </div>
          <div className="col-6"></div>
        </div>

        {/* Repeat for each step */}
        {/* Step 2 - Right Side */}
        <div className="d-flex w-100 mb-4 align-items-center">
          <div className="col-6"></div>
          <div className="col-6 text-start ps-4">
            <div className="bg-[#5B174E] text-white shadow-lg h-100 d-flex flex-column justify-content-center p-3 me-auto border-0">
              <h3 className="card-title fw-bold">Get MetaBet Cash</h3>
              <p>Receive 10,000 METABET credited to your MetaMask account upon registration.</p>
            </div>
          </div>
        </div>

        {/* Step 3 - Left Side */}
        <div className="d-flex w-100 mb-4 align-items-center">
          <div className="col-6 text-end pe-4">
            <div className="bg-[#5B174E] text-white shadow-lg h-100 d-flex flex-column justify-content-center p-3 ms-auto border-0">
              <h3 className="card-title fw-bold">Buy & Sell METABET</h3>
              <p>Manage your METABET coins on any Ethereum-supported exchanges.</p>
            </div>
          </div>
          <div className="col-6"></div>
        </div>

        {/* Step 4 - Right Side */}
        <div className="d-flex w-100 mb-4 align-items-center">
          <div className="col-6"></div>
          <div className="col-6 text-start ps-4">
            <div className="bg-[#5B174E] text-white shadow-lg h-100 d-flex flex-column justify-content-center p-3 me-auto border-0">
              <h3 className="card-title fw-bold">Start Playing</h3>
              <p>Explore our wide range of games and start winning today!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
