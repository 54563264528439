import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import GetStarted from '../components/GetStarted';
import UserLayout from '../components/Layout';

const HowItWorks = () => {
  return (
    <UserLayout>
      <div className="mt-20 text-white p-4 bg-theme">
        {/* Page Header */}
        <div className="text-center mb-5 animate__animated animate__fadeInDown">
          <h1 className="display-4 font-weight-bold mb-3 neon-text">
            How It Works
          </h1>
          <p className="lead text-gray-300">
            Discover the benefits of playing with MetaBet Cash (METABET) on MetaBet Casino!
          </p>
        </div>

        {/* How to Get Started Section */}
        <GetStarted />

        {/* Features & Benefits Section */}
        <section>
          <h2 className="text-center text-3xl font-bold mb-4 neon-text animate__animated animate__fadeInUp">
            Features & Benefits
          </h2>
          <div className="row g-4">
            {[
              {
                title: 'Play with MetaBet Cash',
                items: [
                  'Exclusive Cryptocurrency: Use MetaBet Cash (METABET) to place your bets, earn rewards, and make seamless transactions.',
                  'Secure & Transparent: Powered by blockchain technology, ensuring every transaction is safe and traceable.',
                  'Low Fees & Fast Transactions: Enjoy low transaction costs and instant processing times.',
                ],
              },
              {
                title: 'Wide Range of Games',
                items: [
                  'Casino Classics: Dive into a vast selection of slot machines, poker, blackjack, and more.',
                  'Live Dealer Games: Experience the thrill of live casinos from the comfort of your home.',
                  'Provably Fair: All our games are provably fair, ensuring a level playing field for everyone.',
                ],
              },
              {
                title: 'Generous Bonuses & Promotions',
                items: [
                  'Welcome Bonus: Sign up and receive 10,000 METABET credited directly to your MetaMask account.',
                  'Daily & Weekly Promotions: Keep an eye out for ongoing promotions that boost your chances of winning.',
                  'Loyalty Rewards: Earn loyalty points every time you play, redeemable for exciting prizes and more METABET.',
                ],
              },
              {
                title: 'User-Friendly Platform',
                items: [
                  'Easy Navigation: Intuitive interface for both beginners and seasoned players.',
                  'Mobile Compatibility: Play anytime, anywhere, on any device.',
                  '24/7 Customer Support: Our dedicated support team is here to assist you around the clock.',
                ],
              },
              {
                title: 'Anonymity & Privacy',
                items: [
                  'No Personal Data Required: Play anonymously and protect your identity with MetaBet Cash.',
                  'Data Security: We prioritize your privacy and ensure that all data is encrypted.',
                ],
              },
            ].map((section, index, array) => (
              <div
                key={index}
                className={`${
                  index === array.length - 1 ? 'col-lg-12 mb-4' : 'col-lg-6 mb-4'
                }`}
              >
                <div
                  className={`relative group transform transition duration-500 hover:scale-105 hover:shadow-lg p-6 rounded-lg ${
                    index % 2 === 0
                      ? 'bg-gradient-to-r from-[#4b015c] via-[#7b1d65] to-[#ff4b6b]' // Gradient for even cards
                      : 'bg-gradient-to-l from-[#4b015c] via-[#7b1d65] to-[#ff4b6b]' // Gradient for odd cards
                  } w-full min-h-[320px] flex flex-col justify-between shadow-md`}
                >
                  {/* Card Front */}
                  <div className="flex flex-col items-center justify-center h-full p-4 text-center">
                    <h3 className="text-2xl font-semibold mb-3">{section.title}</h3>
                    <ul className="list-unstyled text-gray-300 text-justify leading-relaxed">
                      {section.items.map((item, i) => (
                        <li key={i} className="mb-2">
                          <FontAwesomeIcon icon={faCheckCircle} className="text-green-400 me-2" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Call to Action Section */}
        <div className="text-center mt-5">
          <h2 className="text-3xl font-bold mb-4 neon-text animate__animated animate__fadeInUp">
            Join MetaBet Casino Today
          </h2>
          <p className="mb-4 text-gray-300">
            The future of online gaming is here. Don’t miss out—join MetaBet Casino and start playing with MetaBet Cash today!
          </p>
          <a href='/register'>
          <button className="btn btn-warning btn-lg animate__animated animate__pulse animate__infinite">
          Grab Your 10,000 METABET Bonus!
          </button>
          </a>
        </div>
      </div>
    </UserLayout>
  );
};

export default HowItWorks;
