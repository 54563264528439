import React from 'react';
import UserLayout from '../components/Layout';
import Banner from '../components/Home/Banner';
import About from '../components/Home/About';
import GameSection from '../components/Home/GameSection';
import Slideshow from '../components/Home/Slideshow';
import Footer from '../components/Footer';
import Header from '../components/Header';
import GetStarted from '../components/GetStarted';
import GameList from '../components/GameList';


const UserGames = () => {
  return (
    <UserLayout>
    <div className="user-layout">
      {/* <Header /> */}
        <GameList/>
      {/* <Footer/> */}
      </div>
    </UserLayout>
  );
};

export default UserGames;
