import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import Layout from '../components/Layout';
import Swal from 'sweetalert2';
import axios from 'axios';

const CryptoRequests = () => {
  const [cryptoRequests, setCryptoRequests] = useState([]); // State to store crypto requests data
  const [loading, setLoading] = useState(true); // State to handle loading

  useEffect(() => {
    // Fetch all crypto requests on component mount
    const fetchCryptoRequests = async () => {
      try {
        const response = await axios.get('https://www.blueblocksol.com:3000/users/crypto-requests'); // Replace with your actual API endpoint

        if (response.status === 200) {
          setCryptoRequests(response.data);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch crypto requests.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error fetching crypto requests: ' + (error.response?.data?.message || error.message),
        });
        console.error('Error:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCryptoRequests();
  }, []);

  // Function to handle the approval of a request
  const handleApproveRequest = async (reqid) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to approve this request?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, approve it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Make API call to approve request
          await axios.post(`https://www.blueblocksol.com:3000/users/crypto-request/approve`, { reqid });

          setCryptoRequests((prevRequests) =>
            prevRequests.map((request) =>
              request.reqid === reqid ? { ...request, status: 'Approved' } : request
            )
          );

          Swal.fire('Approved!', 'The request has been approved.', 'success');
        } catch (error) {
          console.error('Error approving request:', error);
          Swal.fire('Error', 'Failed to approve the request.', 'error');
        }
      }
    });
  };

  // Function to handle the disapproval of a request
  const handleDisapproveRequest = async (reqid) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to disapprove this request?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, disapprove it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Make API call to disapprove request
          await axios.post(`https://www.blueblocksol.com:3000/users/crypto-requests/disapprove`, { reqid });

          setCryptoRequests((prevRequests) =>
            prevRequests.map((request) =>
              request.reqid === reqid ? { ...request, status: 'Disapproved' } : request
            )
          );

          Swal.fire('Disapproved!', 'The request has been disapproved.', 'success');
        } catch (error) {
          console.error('Error disapproving request:', error);
          Swal.fire('Error', 'Failed to disapprove the request.', 'error');
        }
      }
    });
  };

  // Define columns for the datatable
  const columns = [
    { name: 'Request ID', selector: (row) => row.reqid, sortable: true },
    { name: 'User ID', selector: (row) => row.user_id, sortable: true },
    { name: 'Amount', selector: (row) => row.amount, sortable: true },
    { name: 'Request Date', selector: (row) => row.request_date, sortable: true },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <span>
          {row.status === 'Pending' && <i className="ti ti-md ti-alert text-warning" title="Pending"></i>}
          {row.status === 'Approved' && <i className="ti ti-md ti-check text-success" title="Approved"></i>}
          {row.status === 'Disapproved' && <i className="ti ti-md ti-close text-danger" title="Disapproved"></i>}
          <span className="ms-2">{row.status}</span>
        </span>
      ),
    },
    { name: 'Transaction ID', selector: (row) => row.transaction_id, sortable: true },
    { name: 'Mail Sent', selector: (row) => row.mail_send, sortable: true },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <button
            className="btn btn-link p-0 text-success"
            onClick={() => handleApproveRequest(row.reqid)}
            disabled={row.status !== 'Pending'} // Disable button if status is not Pending
          >
            <i className="ti ti-md ti-check"></i>
          </button>
          <button
            className="btn btn-link p-0 text-danger"
            onClick={() => handleDisapproveRequest(row.reqid)}
            disabled={row.status !== 'Pending'} // Disable button if status is not Pending
          >
            <i className="ti ti-md ti-close"></i>
          </button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <Layout>
      <div className="card">
        <h5 className="card-header">Crypto Requests</h5>
        <div className="card-datatable text-nowrap">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={cryptoRequests}
              pagination
              highlightOnHover
              striped
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CryptoRequests;
